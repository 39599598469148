import React from 'react'
import { v4 } from 'uuid'

import Footer from '../components/Organisms/Footer'
import { FooterColumn } from '../components/Organisms/Footer/common.types'

const demoCols: FooterColumn[] = [
  {
    id: v4(),
    name: 'About',
    links: [
      { url: 'test', text: 'test', id: v4() },
      { url: 'test', text: 'test', id: v4() },
    ],
  },
  {
    id: v4(),
    name: 'Team',
    links: [
      { url: 'test', text: 'test', id: v4() },
      { url: 'test', text: 'test', id: v4() },
    ],
  },
]

export default () => <Footer data={demoCols} editMode />
